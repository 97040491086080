import * as React from 'react'
import { t } from 'ttag'
import {observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import {Alert, Button} from 'reactstrap'
import {ClaimFormState, getStateInstance} from './state'
import {navigate} from '../../util/navigate'
import {getLocale} from '../../locales'


@observer
export class ClaimSubmitted
    extends React.Component<{pageState?: ClaimFormState, location: Location}> {

    render () {
        let pageState = getStateInstance()
        let claimNr = pageState.lastClaimNumber
        let emailAddress = pageState.lastClaimEmail

        return <Alert color="success" className="mt-5 px-4 py-4">
            <div>
                {t`
                    The claim was successfully submitted with claim number ${claimNr} and
                    you will receive a confirmation message at ${emailAddress} shortly. 
                `}
            </div>

            <div className="mt-3">
                {t`Did you have any other delayed or cancelled flights?`}

                <Button onClick={this.onNewClaimClick} color="link" block className="submit">
                    Claim compensation for another flight
                </Button>
            </div>


        </Alert>
    }

    @autobind
    onNewClaimClick () {
        getStateInstance().resetForm()
        navigate('/claim-form', getLocale(this.props.location).shortCode)
    }
}
