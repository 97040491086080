import * as React from 'react'
import {Helmet} from 'react-helmet'
import {Container} from 'reactstrap'
import {ClaimFormState} from '../../components/ClaimForm/state'
import {Provider, observer} from 'mobx-react'
import {ClaimSubmitted} from '../../components/ClaimForm/ClaimSubmitted'
import Layout from '../../components/Layout/Layout'

import './claim-form.scss'
import DebugStepLinks from '../../components/ClaimForm/DebugStepLinks'


@observer
export default class ClaimRegisteredPage extends React.Component<{location: Location}> {
    render () {
        return <Layout className="claim-form-page">
            <Container>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>

                <div className="row mb-5">

                    <div className="offset-md-2 col-md-8">
                        <ClaimSubmitted location={this.props.location}/>
                    </div>
                </div>

            </Container>

            <DebugStepLinks/>

        </Layout>
    }
}
